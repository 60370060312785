import { QueryClient, useQuery, UseQueryOptions } from 'react-query'
import { MyURL } from 'repositories/http/url/url'
import { ApiClient } from 'services/ApiClient'
import { Api } from 'types/Api'
import { MarketplaceProperty } from 'model/Property'
import { propertiesKeys } from './keys'

export namespace FetchProperties {
  export type Response = MarketplaceProperty
  export type Error = Api.Error
  export type Options = UseQueryOptions<Response, Error>
}

const queryFetcher = (slug: string) => {
  const uri = new MyURL(`/properties/${slug}`, [])
  return ApiClient.get<FetchProperties.Response>(uri.toString())
}

export const usePropertyBySlug = (slug: string, options?: FetchProperties.Options) => {
  return useQuery<FetchProperties.Response, FetchProperties.Error>(propertiesKeys.bySlug(slug), () => queryFetcher(slug), options)
}

export const prefetchPropertyBySlug = async (qclient: QueryClient, slug: string, options?: FetchProperties.Options) => {
  await qclient.prefetchQuery<FetchProperties.Response, FetchProperties.Error>(propertiesKeys.bySlug(slug), () => queryFetcher(slug), options)
}
