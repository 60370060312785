import { QueryStringer } from './qs'

export class MyURL {
  url
  queries: QueryStringer[] = []

  constructor(url: string, queries: QueryStringer[]) {
    this.url = url
    this.queries = queries
  }

  queryParamsToString(): string {
    return this.queries.map((q) => q.queryString()).join('&')
  }

  toString() {
    if (this.queries.length === 0) {
      return this.url.toString()
    }
    return `${this.url}?${this.queryParamsToString()}`
  }
}
