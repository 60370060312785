import classNames from 'classnames'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { useTranslation } from 'hooks/useTranslation'
import { FC } from 'react'
import styles from './MonthlySavingsTag.module.scss'

type MonthlySavingsTagProps = {
  monthlySavings: number
  containerClass?: string
  contentClass?: string
}

export const MonthlySavingsTag: FC<MonthlySavingsTagProps> = ({ monthlySavings, containerClass, contentClass }) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.container, containerClass)}>
      <Typography typographyType={TypographyType.SMALL_PARAGRAPH} className={classNames(styles.content, contentClass)}>
        {t('common:propertyMonthlySavings', { monthlySavings })}
      </Typography>
    </div>
  )
}
