import { StepsModule } from 'components/StepsModule/StepsModule'
import { useTranslation } from 'hooks/useTranslation'
import { TranslationKeys } from 'i18n/locales/translationKeys'
import { useEffect } from 'react'

type HowSmarthousingWorksProps = {
  title?: TranslationKeys
}

export const HowSmarthousingWorks = ({ title = 'home:how-it-works.title' }: HowSmarthousingWorksProps) => {
  const { t } = useTranslation()

  const stepsFeatures = [
    {
      stepNumber: 1,
      title: t('home:how-it-works.features.first-feature.title'),
      description: t('home:how-it-works.features.first-feature.subtitle'),
      imgSrc: '/images/icons/feature_1.svg',
    },
    {
      stepNumber: 2,
      title: t('home:how-it-works.features.second-feature.title'),
      description: t('home:how-it-works.features.second-feature.subtitle'),
      imgSrc: '/images/icons/feature_2.svg',
    },
    {
      stepNumber: 3,
      title: t('home:how-it-works.features.third-feature.title'),
      description: t('home:how-it-works.features.third-feature.subtitle'),
      imgSrc: '/images/icons/feature_3.svg',
    },
    {
      stepNumber: 4,
      title: t('home:how-it-works.features.fourth-feature.title'),
      description: t('home:how-it-works.features.fourth-feature.subtitle'),
      imgSrc: '/images/icons/feature_4.svg',
    },
  ]

  useEffect(() => {
    const head = document.querySelector('head')

    // Calendly styles
    const stylesheet = document.createElement('link')
    stylesheet.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css')
    stylesheet.setAttribute('rel', 'stylesheet')

    // Calendly script
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    script.setAttribute('type', 'text/javascript')

    if (head) {
      head.appendChild(script)
      head.appendChild(stylesheet)
    }
  }, [])

  return (
    <StepsModule
      title={t(title)}
      subtitle={t('home:how-it-works.subtitle')}
      features={stepsFeatures}
      buttonText={t('home:how-it-works.button-contact-team')}
      buttonLink="/how-it-works"
    />
  )
}
