/* eslint-disable */
import { MarketplaceProperties } from 'model/Property'
import { QueryClient, useQuery, UseQueryOptions } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { ApiClient } from 'services/ApiClient'
import { Api } from 'types/Api'

export namespace FetchPropertiesMostVisited {
  export type Response = MarketplaceProperties
  export type Error = Api.Error
  export type Options = UseQueryOptions<Response, Error>
}

const createKey = () => 'properties-most-visited'

const queryFetcher = () => {
  return ApiClient.get<FetchPropertiesMostVisited.Response>('/properties/most-visited')
}

export const useFetchPropertiesMostVisited = (options?: FetchPropertiesMostVisited.Options) => {
  return useQuery<FetchPropertiesMostVisited.Response, FetchPropertiesMostVisited.Error>(createKey(), () => queryFetcher(), options)
}

export const prefetchPropertiesMostVisited = async (options?: FetchPropertiesMostVisited.Options) => {
  const queryClient = new QueryClient()

  await queryClient.prefetchQuery<FetchPropertiesMostVisited.Response, FetchPropertiesMostVisited.Error>(
    createKey(),
    () => queryFetcher(),
    options
  )

  return {
    dehydratedState: dehydrate(queryClient),
  }
}
